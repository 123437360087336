import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    brands: [],
    brand: null,
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
};
// ----------------------------------------------------------------------
export const getBrands = createAsyncThunk('brand/getBrands', async ({ page = 1, rowsPerPage = 10, orderBy = 'createdAt', order = 'desc', isPaging = true, filterName = '', filterActive = '', rentableTypeId = '', }) => {
    let data;
    const queryParams = {
        search: {
            'rentableType.id': rentableTypeId,
            name: filterName,
            active: filterActive !== '' ? Number(filterActive) : filterActive,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/brands', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getBrand = createAsyncThunk('brand/getBrand', async (id) => {
    let data;
    try {
        const response = await axios.get(`/brands/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createBrand = createAsyncThunk('brand/createBrand', async (Data) => {
    let data;
    try {
        const formData = serialize({
            active: Data.active,
            name: Data.name.toString(),
            code: Data.code.toString(),
            rentableTypeId: Data.rentableTypeId.toString(),
            image: Data.image,
            icon: Data.icon,
        });
        const response = await axios.post(`/brands`, formData);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editBrand = createAsyncThunk('brand/editBrand', async ({ id, Data }) => {
    let data;
    try {
        const formData = serialize({
            active: Data.active,
            name: Data.name.toString(),
            code: Data.code.toString(),
            rentableTypeId: Data.rentableTypeId.toString(),
            image: Data.image,
            icon: Data.icon,
        });
        const response = await axios.put(`/brands/${id}`, formData);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteBrand = createAsyncThunk('brand/deleteBrand', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/brands/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getBrands({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyBrands = createAsyncThunk('brand/deleteManyBrands', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/brands/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getBrands({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'brand',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBrands.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBrands.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.brands = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getBrands.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBrand.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBrand.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.brand = action.payload.data;
        })
            .addCase(getBrand.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createBrand.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createBrand.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createBrand.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editBrand.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editBrand.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(editBrand.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
